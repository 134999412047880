var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index-main" }, [
    _c(
      "div",
      {
        staticClass: "index-item blue",
        on: {
          click: function ($event) {
            return _vm.goUrl("/questionLibList")
          },
        },
      },
      [
        _c("div", { staticClass: "zhixing icon-lianxi" }),
        _c("div", [_vm._v("题库练习")]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "index-item",
        on: {
          click: function ($event) {
            return _vm.goChooseTaskId(1)
          },
        },
      },
      [
        _c("div", { staticClass: "zhixing icon-kaoshijilu" }),
        _c("div", [_vm._v("线上考试")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }