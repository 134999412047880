import { render, staticRenderFns } from "./competition.vue?vue&type=template&id=a75c0bd6&scoped=true&"
import script from "./competition.vue?vue&type=script&lang=js&"
export * from "./competition.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a75c0bd6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\LiangXi\\examination-h5-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a75c0bd6')) {
      api.createRecord('a75c0bd6', component.options)
    } else {
      api.reload('a75c0bd6', component.options)
    }
    module.hot.accept("./competition.vue?vue&type=template&id=a75c0bd6&scoped=true&", function () {
      api.rerender('a75c0bd6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/competition.vue"
export default component.exports