<template>
  <div class="main-container">
    <div class="main-conversation" ref="conversation">
      <section v-for="(item, index) in conversationList" :key="index">
        <Mrespond v-if="item.answer && item['done']" :msg="item.answer" :docs="item.docs" />
        <Mrespond
          v-if="item.answer && conversationList['length'] == index + 1 && loading == true"
          :msg="responseText"
        />
        <Mquestion v-if="item.question" :msg="item.question" />
      </section>
    </div>
  </div>
</template>
<script>
import { conversationData } from '@/api/modules/index'

export default {
  name: 'answerData',
  components: {
    Mquestion: () => import('@/components/Message/question.vue'),
    Mrespond: () => import('@/components/Message/respond.vue'),
  },
  props: {},
  data() {
    return {
      conversationId: '',
      loading: false,
      responseText: '',
      conversationList: [
        {
          randomId: 1,
          answer: '你好，我是梁溪区燕溪大模型（问数）',
          conversationId: '',
          done: true,
        },
        // {
        //   question: '你好，我是梁溪区燕溪大模型',
        //   // conversationId: '',
        // },
      ],
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    sendQuestion(msg) {
      const _this = this

      this.conversationList.push({
        // randomId: this.conversationList.length + 1,

        question: msg,
      })

      this.loading = true

      conversationData({
        conversationId: this.conversationId,
        content: msg,
      })
        .then((response) => {
          const reader = response.body.getReader()
          const decoder = new TextDecoder('utf-8')
          let buffer = ''

          function processStreamResult(result2) {
            const chunk = decoder.decode(result2.value, { stream: !result2.done })
            buffer += chunk
            //逐条解析后端返回数据
            let lines = buffer.split('\n')
            buffer = lines.pop()

            lines.forEach((line) => {
              if (line.trim().length > 0) {
                line = line.replace('data:', '')
                // console.log('line---->:', line)

                if (line == '[DONE]') {
                  //执行完成的页面逻辑
                  console.log('done')
                  console.log('---->', _this.conversationList[_this.conversationList.length - 1])
                  _this.conversationList[_this.conversationList.length - 1]['done'] = true
                  _this.loading = false
                } else {
                  // console.log('next')
                  // console.log(line)
                  try {
                    // console.log(JSON.parse(line))
                    // console.log(_this.handelMsg)
                    _this.handelMsg(JSON.parse(line))
                  } catch (error) {}
                }
              }
            })

            if (!result2.done) {
              return reader.read().then(processStreamResult)
            }
          }

          return reader.read().then(processStreamResult)
        })
        .then((body) => {
          console.log('body:', body)
        })
        .catch((error) => {
          //console.error('Error:', error);
        })
    },
    handelMsg(msg) {
      // console.log(msg)
      if (this.conversationList[this.conversationList.length - 1]['question']) {
        console.log('question-----ok')
        this.conversationId = msg['conversationId']
        this.conversationList.push({
          answer: 'loading',
          docs: [],
        })
      }
      if (msg['type'] === 'message') {
        // msg['randomId'] = UUID.create()['hex']
        this.responseText = msg['answer']
        this.conversationList[this.conversationList.length - 1] = msg
        // try {
        //   if (msg['docs']) {
        //     msg['docs'].forEach((item) => {
        //       const md = new MarkdownIt()

        //       const jsonOutput = md.parse(item, {})

        //       console.log(jsonOutput)
        //     })
        //   }
        // } catch (error) {}
      }
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.main-container {
  // background-color: rgba(247, 248, 251, 1);
  background-color: #f1f2f6;

  .main-conversation {
    overflow-y: scroll;
    padding-bottom: 80px;
    padding-top: 44px;
  }
}
</style>
