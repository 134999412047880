<template>
  <div class="content-main">
    <swiper class="swiper" :options="swiperOption" ref="mySwiper" :key="runderKey">
      <div class="swiperslide swiper-slide" v-for="(item, index) in questionList" :key="item['id']">
        <multiple-choice-question
          v-if="item['questionType'] === 0"
          @choose="choose"
          :question="item"
          :question-index="index"
          :trainning="true"
        />
        <multiple-choice-withdrawal
          v-if="item['questionType'] === 1"
          @choose="choose"
          :question="item"
          :question-index="index"
          :trainning="true"
        />
        <true-false-question
          v-if="item['questionType'] === 2"
          @choose="choose"
          :question="item"
          :question-index="index"
          :trainning="true"
        />
        <short-answer-questions
          v-if="item['questionType'] === 3"
          @choose="choose"
          :question="item"
          :question-index="index"
          :trainning="true"
        />
      </div>
    </swiper>
    <van-overlay :show="loading">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" color="#1989fa" vertical> 加载中... </van-loading>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { getQuestionList, getQuestion, getAnswerErrorList } from '@/api/modules/examine/index'
export default {
  name: 'Practice',
  components: {
    Swiper,
    SwiperSlide,
    MultipleChoiceQuestion: () => import('@/components/MultipleChoiceQuestion/index.vue'), // 单选
    MultipleChoiceWithdrawal: () => import('@/components/MultipleChoiceWithdrawal/index.vue'), // 多选
    TrueFalseQuestion: () => import('@/components/TrueFalseQuestion/index.vue'), // 判断
    ShortAnswerQuestions: () => import('@/components/ShortAnswerQuestions/index.vue'), // 简答
  },
  props: {},
  data() {
    return {
      answerlist: [],
      questionList: [],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        observer: true,
        autoHeight: true,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        // },
        on: {
          slideChange: () => {
            // console.log('轮播图滚动事件  --->', this.$refs.mySwiper.$swiper.activeIndex)
            this.SET_QUESTION_INDEX(this.$refs.mySwiper.$swiper.activeIndex)
            this.$EventBus.$emit('questionMsg', this.questionList[this.$refs.mySwiper.$swiper.activeIndex]['question'])
          },
        },
      },
      loading: false,
      runderKey: 1,
      spliceQuestionList: [],
      questionLibId: '', //题库id
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {
    this.questionLibId = this.$route.query.questionLibId
  },
  beforeMount() {},
  mounted() {
    this.SET_TRAINNING(true)

    this.SET_END_QUESTION(false)
    this.SET_QUESTION_INDEX(0)
    this.SET_ANSWER_LIST([])
    if (this.$route.query.taskId) {
      this.initError()
    } else {
      this.initNormal()
    }
    this.$EventBus.$on('swiperGo', (item) => {
      let itemIndex = this.questionList.findIndex((questionItem) => questionItem.id === item.id)
      this.goToSlide(itemIndex)
    })
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    ...mapMutations([
      'SET_QUESTION_LIST',
      'SET_ANSWER_LIST',
      'SET_QUESTION_INDEX',
      'SET_QUESTION_RULE_DTO_LIST',
      'SET_PAGE_LOADING',
      'SET_END_QUESTION',
      'SET_TRAINNING',
    ]),
    goToSlide(index) {
      this.$nextTick(() => {
        this.$refs.mySwiper.$swiper.slideTo(index)
      })
    },
    choose(key, index, item) {
      console.log('choose--->', key, index, item)
      let existingItem = this.answerlist.find((answer) => answer.questionId === item.id)

      if (existingItem) {
        Object.assign(existingItem, {
          questionType: item.questionType,
          questionId: item.id,
          answerContent: key,
        })
      } else {
        this.answerlist.push({
          questionType: item.questionType,
          questionId: item.id,
          answerContent: key,
        })
      }
      this.SET_ANSWER_LIST(this.answerlist)
    },
    initNormal() {
      // // this.loading = true
      this.SET_PAGE_LOADING(true)
      getQuestion({
        questionLibId: this.questionLibId,
      })
        .then((res) => {
          console.log('getQuestion', res)

          this.SET_QUESTION_LIST(res.onlineExamineQuestionList)
          res.onlineExamineQuestionList.forEach((item, questionIndex) => {
            res.questionRuleDtoList.forEach((rule) => {
              if (!Array.isArray(rule.questionList)) {
                rule.questionList = []
              }
              if (item.questionType === rule.questionType) {
                rule.questionList.push(item)
                // rule.questionList.push({
                //   ...item,
                //   onlineExamineQuestionIndex: questionIndex,
                // })
                // item['questionType'] = rule.questionType
              }
            })
          })
          this.SET_QUESTION_RULE_DTO_LIST(res.questionRuleDtoList)
          // console.log('res.questionRuleDtoList', res.questionRuleDtoList)
          let staticonlineExamineQuestionList = JSON.parse(JSON.stringify(res.onlineExamineQuestionList))
          // this.questionList = staticonlineExamineQuestionList.splice(0, 10)
          this.questionList = staticonlineExamineQuestionList
          // this.spliceQuestionList = staticonlineExamineQuestionList
          this.$EventBus.$emit('questionMsg', this.questionList[0]['question'])
        })
        .finally(() => {
          console.log('finally')
          /*           this.$nextTick(() => {
            setTimeout(() => {
              this.SET_PAGE_LOADING(false)
            }, 100)
            // this.SET_PAGE_LOADING(false)
          }) */
          this.SET_PAGE_LOADING(false)

          // this.loading = false
          // this.loading = false
          //   this.$nextTick(() => {
          //   })
          //   console.dir(this.$refs.mySwiper)
          //   setTimeout(() => {
          //     // this.runderKey = new Date().valueOf()
          //     this.loading = false
          //     this.$refs.mySwiper.updateSwiper()
          //     console.log('this.$refs.mySwiper:', this.$refs.mySwiper)
          //   }, 4000)
        })
    },
    add(rows) {
      // let addRows = rows
      if (this.spliceQuestionList.length === 0) return
      this.questionList = this.questionList.concat(this.spliceQuestionList.splice(0, 20))
      if (this.spliceQuestionList.length === 0) return
      this.questionList = this.questionList.concat(this.spliceQuestionList.splice(0, 10))
    },
    initError() {
      getAnswerErrorList(this.$route.query).then((res) => {
        this.questionList = res
      })
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.swiper {
  // margin-top: 44px;
  // height: calc(100% - 44px);
  min-height: calc(100% - 44px);
  z-index: 1;
  transform: translateZ(100px); /*这里是给safari用的*/
  .swiper-slide {
    // color: black;
    // background-color: rebeccapurple;
    color: white;
    min-height: calc(100% - 44px);
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
