var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-container" }, [
    _c(
      "div",
      { ref: "conversation", staticClass: "main-conversation" },
      _vm._l(_vm.conversationList, function (item, index) {
        return _c(
          "section",
          { key: index },
          [
            item.answer && item["done"]
              ? _c("Mrespond", { attrs: { msg: item.answer, docs: item.docs } })
              : _vm._e(),
            item.answer &&
            _vm.conversationList["length"] == index + 1 &&
            _vm.loading == true
              ? _c("Mrespond", { attrs: { msg: _vm.responseText } })
              : _vm._e(),
            item.question
              ? _c("Mquestion", { attrs: { msg: item.question } })
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }