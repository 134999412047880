var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-main" },
    [
      !_vm.loading && _vm.taskList.length === 0
        ? _c("van-empty", { attrs: { description: "暂无数据" } })
        : _vm._e(),
      !_vm.loading && _vm.taskList.length !== 0
        ? _c(
            "van-cell-group",
            _vm._l(_vm.taskList, function (item) {
              return _c("van-cell", {
                key: item["id"],
                attrs: { title: item["name"], "is-link": "", label: " " },
                on: {
                  click: function ($event) {
                    return _vm.goUrl(item)
                  },
                },
              })
            }),
            1
          )
        : _vm._e(),
      _c("van-overlay", { attrs: { show: _vm.loading } }, [
        _c(
          "div",
          {
            staticClass: "wrapper",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "van-loading",
              { attrs: { type: "spinner", color: "#1989fa", vertical: "" } },
              [_vm._v(" 加载中... ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }