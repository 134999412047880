var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-main" },
    [
      _c(
        "swiper",
        {
          key: _vm.runderKey,
          ref: "mySwiper",
          staticClass: "swiper",
          attrs: { options: _vm.swiperOption },
        },
        _vm._l(_vm.questionList, function (item, index) {
          return _c(
            "div",
            { key: item["id"], staticClass: "swiperslide swiper-slide" },
            [
              item["questionType"] === 0
                ? _c("multiple-choice-question", {
                    attrs: {
                      question: item,
                      "question-index": index,
                      trainning: true,
                    },
                    on: { choose: _vm.choose },
                  })
                : _vm._e(),
              item["questionType"] === 1
                ? _c("multiple-choice-withdrawal", {
                    attrs: {
                      question: item,
                      "question-index": index,
                      trainning: true,
                    },
                    on: { choose: _vm.choose },
                  })
                : _vm._e(),
              item["questionType"] === 2
                ? _c("true-false-question", {
                    attrs: {
                      question: item,
                      "question-index": index,
                      trainning: true,
                    },
                    on: { choose: _vm.choose },
                  })
                : _vm._e(),
              item["questionType"] === 3
                ? _c("short-answer-questions", {
                    attrs: {
                      question: item,
                      "question-index": index,
                      trainning: true,
                    },
                    on: { choose: _vm.choose },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _c("van-overlay", { attrs: { show: _vm.loading } }, [
        _c(
          "div",
          {
            staticClass: "wrapper",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "van-loading",
              { attrs: { type: "spinner", color: "#1989fa", vertical: "" } },
              [_vm._v(" 加载中... ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }