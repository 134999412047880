<template>
  <div class="content-main">
    <van-empty description="暂无数据" v-if="!loading && taskList.length === 0" />
    <van-cell-group v-if="!loading && taskList.length !== 0">
      <van-cell
        v-for="item in taskList"
        :key="item['id']"
        :title="item['name']"
        is-link
        @click="goUrl(item)"
        label=" "
      />
    </van-cell-group>
    <van-overlay :show="loading">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" color="#1989fa" vertical> 加载中... </van-loading>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { getQuestionlibList } from '@/api/modules/examine/index'
export default {
  name: 'TaskIdList',
  components: {},
  props: {},
  data() {
    return {
      formal: null,
      taskList: [],
      loading: true,
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.init()
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    init() {
      getQuestionlibList()
        .then((res) => {
          if (res.code === 200) {
            this.taskList = res.rows
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loading = false
          })
        })
    },
    goUrl(item) {
      this.$router.push({
        path: '/practice',
        query: { questionLibId: item.id },
      })
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.content-main {
  // margin-top: 44px;
  height: 100%;

  /deep/ .van-cell__label {
    font-size: 10px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
