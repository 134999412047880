<template>
  <div class="content-main">
    <!-- <div v-if="!$route.query.totalScore">
      <div class="score-title">
        客观题得分
      </div>
      <div class="score score-num">
        {{ $route.query.objectiveScore || '-' }}
      </div>
      <div class="score-title">主观题得分</div>
      <div class="score" :class="$route.query.subjectiveScore ? 'score-num' : 'score-text'">
        {{ JSON.parse($route.query.shortAnswerRatingAll) ? '$route.query.subjectiveScore' : '待赋分' }}
      </div>
    </div> -->
    <div class="total-main">
      <div class="score score-num">
        {{ $route.query.objectiveScore || 0 }}
      </div>
      <div class="score-title">您的得分</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SimulationResolveFormal',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {},
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.content-main {
  // margin-top: 44px;
  // height: calc(100% - 44px);
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  .score-title {
    margin-bottom: 20px;
    padding: 20px;
    font-size: 24px;
  }
  .score {
    text-align: center;
    font-weight: bold;
  }
  .score-num {
    font-size: 32px;
  }
  .score-text {
    font-size: 22px;
  }
  .total-main {
    margin-top: 100px;
    text-align: center;
  }
}
</style>
