<template>
  <div class="content-main">
    <van-empty description="暂无数据" v-if="!loading && taskList.length === 0" />
    <van-cell-group v-if="!loading && taskList.length !== 0">
      <van-cell
        v-for="item in taskList"
        :key="item['id']"
        :title="item['name']"
        is-link
        @click="goUrl(item)"
        :label="
          $route.query.formal === '1'
            ? `考试时间:${item['startTime']}-${item['endTime']} 时长:${item['durtion']}分钟`
            : ''
        "
      />
    </van-cell-group>
    <van-overlay :show="loading">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" color="#1989fa" vertical> 加载中... </van-loading>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Dialog, Notify } from 'vant'
import { getQuestionBankList, getAnswerDetail } from '@/api/modules/examine/index'
export default {
  name: 'TaskIdList',
  components: {},
  props: {},
  data() {
    return {
      formal: null,
      taskList: [],
      loading: true,
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    console.log(this.$route.query.formal)
    this.init(this.$route.query.formal)
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    init(formal) {
      getQuestionBankList({
        formal: formal,
      })
        .then((res) => {
          console.log(res)
          if (res.code === 200) {
            this.taskList = res.rows
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loading = false
          })
        })
    },
    goUrl(item) {
      console.log('goUrl', item, this.$route.query.formal)
      // 如果考试已失效 添加提醒
      if (item.status === 1) {
        Notify({
          type: 'warning',
          message: '该考试已失效，请选择其他考试',
          duration: 1000,
        })
        return
      }

      //如果 userPaperId有分数 则跳转得分页面
      if (item.userPaperId) {
        getAnswerDetail({
          paperId: item.userPaperId,
          taskId: item.id,
          userId: '',
        }).then((res) => {
          console.log(res)
          let objectiveScore = null
          if (res['questionInfoList'] && res['questionInfoList'].length > 0) {
            objectiveScore = res['questionInfoList'].reduce((sum, item) => sum + item.ratingScore, 0)
          }
          this.$router.push({
            path: 'simulationResolveFormal',
            query: {
              paperId: item.userPaperId,
              totalScore: res.shortAnswerRatingAll ? objectiveScore + res.shortAnswerRatingTotalScore : null,
              objectiveScore: objectiveScore, //客观题得分
              shortAnswerRatingAll: res.shortAnswerRatingAll,
              subjectiveScore: res.shortAnswerRatingTotalScore || '', //主观题得分
            },
          })
        })

        return
      }

      if (this.$route.query.formal === '0') {
        this.$router.push({
          path: 'simulation',
          query: { taskId: item.id, durtion: item.durtion, formal: item.formal },
        })
      }
      if (this.$route.query.formal === '1') {
        // 正式考试提醒
        Dialog.confirm({
          title: '提示',
          message: '您好，进入考试之后将开始倒计时，请答完题再退出，否则倒计时结束将无法再次进入',
        })
          .then(() => {
            this.$router.push({
              path: 'simulation',
              query: { taskId: item.id, durtion: item.durtion, formal: item.formal },
            })
          })
          .catch(() => {
            // on cancel
          })
      }
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.content-main {
  height: 100%;

  /deep/ .van-cell__label {
    font-size: 10px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
