<template>
  <div class="content-main">
    <div class="indicate-main">
      <van-progress :percentage="progress" stroke-width="15" />
      <div class="indicate-time">
        <van-count-down
          ref="countDown"
          :auto-start="false"
          :time="time"
          format="mm 分 ss 秒"
          @change="timeChange"
          @finish="timeFinish"
        />
      </div>
      <div class="indicate-count">{{ answerlist.length }}/{{ questionist.length }}</div>
    </div>
    <swiper class="swiper" :options="swiperOption" ref="mySwiper" @reachEnd="swiperEnd">
      <swiper-slide v-for="(item, index) in questionist" :key="item['id']">
        <multiple-choice-question
          v-if="item['questionType'] === 0"
          @choose="choose"
          :question="item"
          :question-index="index"
        />
        <multiple-choice-withdrawal
          v-if="item['questionType'] === 1"
          @choose="choose"
          :question="item"
          :question-index="index"
        />
        <true-false-question
          v-if="item['questionType'] === 2"
          @choose="choose"
          :question="item"
          :question-index="index"
        />
        <short-answer-questions
          v-if="item['questionType'] === 3"
          @choose="choose"
          :question="item"
          :question-index="index"
          :trainning="formal == '1' ? false : true"
        />
      </swiper-slide>
    </swiper>
    <!-- <div class="confirm-selection-main" v-if="endQuestion">
      <van-button type="primary" block @click="submitChoose"> 确认提交 </van-button>
    </div> -->
    <van-overlay :show="loading">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" color="#1989fa" vertical> 加载中... </van-loading>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'

import { addAndReturnQuestionList, submitAnswer } from '@/api/modules/examine/index'
import dayjs from 'dayjs'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { Dialog } from 'vant'
import 'swiper/css/swiper.css'
export default {
  name: 'SimulationTesting',
  components: {
    Swiper,
    SwiperSlide,
    MultipleChoiceQuestion: () => import('@/components/MultipleChoiceQuestion/index.vue'), // 单选
    MultipleChoiceWithdrawal: () => import('@/components/MultipleChoiceWithdrawal/index.vue'), // 多选
    TrueFalseQuestion: () => import('@/components/TrueFalseQuestion/index.vue'), // 判断
    ShortAnswerQuestions: () => import('@/components/ShortAnswerQuestions/index.vue'), // 简答
  },
  props: {},
  data() {
    return {
      answerlist: [],
      questionist: [],
      progress: 100,
      time: null,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoHeight: true,
        on: {
          slideChange: () => {
            // console.log('轮播图滚动事件  --->', this.$refs.mySwiper.$swiper.activeIndex)
            this.SET_QUESTION_INDEX(this.$refs.mySwiper.$swiper.activeIndex)
          },
        },
      },
      loading: false,
      endQuestion: false,
      userTaskPaperId: 0,
      formal: '', // 是否是竞赛
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {
    this.formal = this.$route.query.formal
    if (this.$route.query.formal === '1') {
      document.title = '线上考试'
    }
    this.time = Number(this.$route.query.durtion) * 1000 * 60
  },
  beforeMount() {},
  mounted() {
    if (this.$route.query.formal === '1') {
      this.SET_TRAINNING(false)
    } else {
      this.SET_TRAINNING(true)
    }
    this.SET_END_QUESTION(false)
    this.SET_QUESTION_INDEX(0)
    this.SET_ANSWER_LIST([])
    this.init()
    this.$EventBus.$on('swiperGo', (item) => {
      let itemIndex = this.questionist.findIndex((questionItem) => questionItem.id === item.id)
      this.goToSlide(itemIndex)
    })

    this.$EventBus.$off('examSubmit')
    this.$EventBus.$on('examSubmit', () => {
      this.submitChoose()
    })
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    ...mapMutations([
      'SET_QUESTION_LIST',
      'SET_ANSWER_LIST',
      'SET_QUESTION_INDEX',
      'SET_QUESTION_RULE_DTO_LIST',
      'SET_PAGE_LOADING',
      'SET_END_QUESTION',
      'SET_TRAINNING',
    ]),
    goToSlide(index) {
      this.$nextTick(() => {
        this.$refs.mySwiper.$swiper.slideTo(index)
      })
    },
    choose(key, index, item) {
      let existingItem = this.answerlist.find((answer) => answer.questionId === item.id)

      if (existingItem) {
        Object.assign(existingItem, {
          questionType: item.questionType,
          questionId: item.id,
          answerContent: key,
        })
      } else {
        this.answerlist.push({
          questionType: item.questionType,
          questionId: item.id,
          answerContent: key,
        })
      }
      this.SET_ANSWER_LIST(this.answerlist)
    },
    timeChange(timeData) {
      if (timeData) {
        this.progress =
          ((timeData.days * 24 * 60 * 60 * 1000 +
            timeData.hours * 60 * 60 * 1000 +
            timeData.minutes * 60 * 1000 +
            timeData.seconds * 1000) /
            this.time.toFixed(2)) *
          100
      }
    },
    timeFinish() {
      Dialog.alert({
        message: '考试时间结束',
      }).then(() => {
        this.submit()
      })
    },
    init() {
      this.SET_PAGE_LOADING(true)

      // console.log(dayjs().format('YYYY-MM-DD HH:mm:ss'))
      addAndReturnQuestionList({
        taskId: this.$route.query.taskId,
        enterTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      })
        .then((res) => {
          this.SET_QUESTION_LIST(res.onlineExamineQuestionList)
          res.onlineExamineQuestionList.forEach((item, questionIndex) => {
            res.questionRuleDtoList.forEach((rule) => {
              if (!Array.isArray(rule.questionList)) {
                rule.questionList = []
              }
              if (item.questionType === rule.questionType) {
                rule.questionList.push(item)
                // rule.questionList.push({
                //   ...item,
                //   onlineExamineQuestionIndex: questionIndex,
                // })
                // item['questionType'] = rule.questionType
              }
            })
          })
          this.SET_QUESTION_RULE_DTO_LIST(res.questionRuleDtoList)
          this.userTaskPaperId = res.userTaskPaperId
          this.questionist = res.onlineExamineQuestionList
          if (this.questionist.length === 1) {
            this.SET_END_QUESTION(true)
          }
          // this.SET_QUESTION_LIST({
          //   list: res.onlineExamineQuestionList,
          //   total: res['total'],
          // })
          // 考试时间 用时
          var new_date = new Date()
          var old_date = new Date(res.enterTime)
          if (new_date == old_date) {
            this.time = Number(this.$route.query.durtion) * 1000 * 60
          } else {
            this.time = Number(this.$route.query.durtion) * 1000 * 60 - (new_date - old_date)
          }
        })
        .catch((err) => {
          setTimeout(() => {
            this.$router.back()
          }, 200)
        })
        .finally(() => {
          this.$nextTick(() => {
            // this.loading = false
            this.SET_PAGE_LOADING(false)

            this.$refs.countDown.start()
          })
        })
    },
    submitChoose() {
      console.log('submitChoose', this.answerlist, this.questionist)

      if (this.answerlist.length === this.questionist.length) {
        console.log('完成所有题目-------------------->');
        this.submit()
      } else {
        Dialog.confirm({
          title: '未完成所有题目',
          message: '未完成所有题目，是否确认提交？',
        })
          .then(() => {
            this.submit()
          })
          .catch(() => {
            // on cancel
          })
      }
    },
    submit() {
      submitAnswer({
        taskId: this.$route.query.taskId,
        paperId: this.userTaskPaperId,
        answerList: this.answerlist,
      })
        .then((res) => {
          console.log('submitAnswer', res)
          this.formal === '1'
            ? this.$router.replace({
                path: 'simulationResolveFormal',
                query: { paperId: this.userTaskPaperId, shortAnswerRatingAll: false, ...res },
              })
            : this.$router.replace({
                path: 'simulationResolve',
                query: { paperId: this.userTaskPaperId, ...res },
              })
          // console.log(res)
        })
        .finally(() => {
          // this.$router.push({ path: 'index' })
        })
    },
    swiperEnd() {
      console.log('swiperEnd------------------->')
      // this.endQuestion = true
      this.SET_END_QUESTION(true)
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.content-main {
  .swiper {
    z-index: 1;
    transform: translateZ(100px); /*这里是给safari用的*/
  }
  .indicate-main {
    // margin-top: 54px;

    height: 15px;
    /deep/ .van-progress__pivot {
      display: none;
    }
    position: relative;
    .indicate-time {
      position: absolute;
      // top: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .indicate-count {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
  .confirm-selection-main {
    z-index: 9999;
    position: absolute;
    bottom: 30px;
    left: 20px;
    right: 20px;
  }
  .swiper {
    // margin-top: 44px;
    // height: calc(100% - 69px);
    min-height: calc(100% - 69px);
    .swiper-slide {
      // color: black;
      // background-color: rebeccapurple;
      color: white;
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
