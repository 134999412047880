var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-main" },
    [
      _c(
        "div",
        { staticClass: "indicate-main" },
        [
          _c("van-progress", {
            attrs: { percentage: _vm.progress, "stroke-width": "15" },
          }),
          _c(
            "div",
            { staticClass: "indicate-time" },
            [
              _c("van-count-down", {
                ref: "countDown",
                attrs: {
                  "auto-start": false,
                  time: _vm.time,
                  format: "mm 分 ss 秒",
                },
                on: { change: _vm.timeChange, finish: _vm.timeFinish },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "indicate-count" }, [
            _vm._v(
              _vm._s(_vm.answerlist.length) +
                "/" +
                _vm._s(_vm.questionist.length)
            ),
          ]),
        ],
        1
      ),
      _c(
        "swiper",
        {
          ref: "mySwiper",
          staticClass: "swiper",
          attrs: { options: _vm.swiperOption },
          on: { reachEnd: _vm.swiperEnd },
        },
        _vm._l(_vm.questionist, function (item, index) {
          return _c(
            "swiper-slide",
            { key: item["id"] },
            [
              item["questionType"] === 0
                ? _c("multiple-choice-question", {
                    attrs: { question: item, "question-index": index },
                    on: { choose: _vm.choose },
                  })
                : _vm._e(),
              item["questionType"] === 1
                ? _c("multiple-choice-withdrawal", {
                    attrs: { question: item, "question-index": index },
                    on: { choose: _vm.choose },
                  })
                : _vm._e(),
              item["questionType"] === 2
                ? _c("true-false-question", {
                    attrs: { question: item, "question-index": index },
                    on: { choose: _vm.choose },
                  })
                : _vm._e(),
              item["questionType"] === 3
                ? _c("short-answer-questions", {
                    attrs: {
                      question: item,
                      "question-index": index,
                      trainning: _vm.formal == "1" ? false : true,
                    },
                    on: { choose: _vm.choose },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
      _c("van-overlay", { attrs: { show: _vm.loading } }, [
        _c(
          "div",
          {
            staticClass: "wrapper",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "van-loading",
              { attrs: { type: "spinner", color: "#1989fa", vertical: "" } },
              [_vm._v(" 加载中... ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }