<template>
  <div>competition</div>
</template>
<script>
export default {
  name: 'competition',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {},
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang='less' type='text/less'>

</style>