var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-main" }, [
    _c("div", { staticClass: "total-main" }, [
      _c("div", { staticClass: "score score-num" }, [
        _vm._v(" " + _vm._s(_vm.$route.query.objectiveScore || 0) + " "),
      ]),
      _c("div", { staticClass: "score-title" }, [_vm._v("您的得分")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }