<template>
  <div class="content-main">
    <div class="score">
      {{ $route.query.objectiveScore ? $route.query.objectiveScore : 0 }}
    </div>
    <div class="score-title">得分</div>
    <div class="show-error" @click="goErrorQuestion">查看本次错题</div>
    <div class="show-tips">错题请及时查看，退出后无法查看</div>
  </div>
</template>
<script>
import { Toast } from 'vant'
export default {
  name: 'SimulationResolve',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    goErrorQuestion() {
      // Toast('本次无错题，希望您再接再厉！')

      if (this.$route.query.errorAnswerFlag === 'true') {
        this.$router.push({
          path: 'errorquestions',
          query: { taskId: this.$route.query.taskId, paperId: this.$route.query.paperId },
        })
      } else {
        Toast({
          message: '本次无错题，希望您再接再厉！',
          icon: require('@/assets/images/fireworks.png'),
        })
      }
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.content-main {
  // margin-top: 44px;
  height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .score {
    margin-top: -30%;
    font-size: 60px;
    font-weight: bold;
  }
  .show-error {
    margin-top: 20px;
    width: 70%;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    background-color: #409eff;
    color: white;
    border-radius: 20px;
    text-align: center;
  }
  .show-tips {
    margin-top: 10px;
    font-size: 15px;
    text-align: center;
  }
}
</style>
