var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-main" }, [
    _c("div", { staticClass: "score" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.$route.query.objectiveScore
              ? _vm.$route.query.objectiveScore
              : 0
          ) +
          " "
      ),
    ]),
    _c("div", { staticClass: "score-title" }, [_vm._v("得分")]),
    _c(
      "div",
      { staticClass: "show-error", on: { click: _vm.goErrorQuestion } },
      [_vm._v("查看本次错题")]
    ),
    _c("div", { staticClass: "show-tips" }, [
      _vm._v("错题请及时查看，退出后无法查看"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }