<template>
  <div class="index-main">
    <div class="index-item blue" @click="goUrl('/questionLibList')">
      <!-- <div class="index-item blue" @click="goChooseTaskId(0)"> -->
      <div class="zhixing icon-lianxi" />
      <div>题库练习</div>
    </div>
    <div class="index-item" @click="goChooseTaskId(1)">
      <div class="zhixing icon-kaoshijilu" />
      <div>线上考试</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'index',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    goUrl(url) {
      console.log(this.$route)
      this.$router.push({ path: url })
      //   this.test()
    },
    goChooseTaskId(formal) {
      this.$router.push({ path: 'choseTaskIdList', query: { formal: formal } })
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.index-main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .index-item {
    width: 100%;
    height: 100%;
    background-color: #07c160;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    .zhixing {
      margin-top: 24px;

      font-size: 45px;
    }
  }
  .blue {
    background-color: #1989fa;
  }
}
</style>
